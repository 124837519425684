export default {
	data() {
		return {

		}
	},
	methods: {
		scrollEvent: (first, second, callback) => e => { // if the first element goes above or under the second, something happens
			let rectOne = document.querySelector(first).getBoundingClientRect()
			// let rectOne = document.querySelector('.sheet-content h2').getBoundingClientRect()
			let rectTwo = document.querySelector(second).getBoundingClientRect()
			// let rectTwo = document.querySelector('.breadcrump').getBoundingClientRect()
			if(rectOne.top + rectOne.height < rectTwo.top + rectTwo.height) callback(true)
			else callback(false)
			// console.log(rectOne.top + rectOne.height, rectTwo.top + rectTwo.height)
		}
	},
	mounted() {

	},
	beforeDestroy() {

	}
}