<template>
	<div class="view-sheet">
		<main class="flow">
			<section class="stack-horizontal bordered-bottom sheet-cont" :data-gap="mobile ? '' : 'none'" v-if="sheetData">
				<section class="stack-vertical sheet-body relative" data-gap="none">
					<div class="breadcrumb stack-horizontal relative" data-align="center">
						<p v-if="topic && (!mobile || !sheetTitleBelowBreadcrumb)" class="text--body1-medium text-no-wrap capitalize-first">{{topic}}</p> 
						<base-icon v-if="topic && !mobile && showTitle" name="arrow-move-right" :size="16" style="position: relative; top: -1px;"/>		
						<p v-if="showTitle" class="text--body1-medium cut-text">{{showSheetTitle}}</p>
					</div>
					<div class="stack-vertical relative sheet-content">
						<h2>{{sheetData.title}}</h2>
						<!-- <interface-user-and-tags 
							:user-actions="userActionsFiltered" 
							@click-user-action="userAction" 
							:load-action="loadActionUser"
							ref="user-and-tags"
						/> -->
						<h3 v-if="sheetData.subtitle" class="text--subtitle">{{ sheetData.subtitle }}</h3>
						<div class="page-buttons-cont stack-horizontal">
							<base-button v-for="(btn, i) in userActionsFiltered" :key="i" class="text-no-wrap" :text="utils.capitalizeFirst(btn.text)" type="tertiary" @clicked="userAction(btn.icon)" />
						</div>
						<img v-if="sheetData.media_file && !sheetData.is_video && sheetData.data_type.toLowerCase() != 'audio'" v-show="portraitRatioFixClass.length" id="sheet-image-cover" :class="portraitRatioFixClass" :src="utils.parseMediaUrl(sheetData.media_file)">
						<video v-else-if="sheetData.media_file && sheetData.is_video" :src="utils.parseMediaUrl(sheetData.media_file)" controls playsinline class="sheet-video" :poster="videoPlaceholder"></video>
						<audio v-else-if="sheetData.data_type.toLowerCase() == 'audio'" controls="false" controlsList="nodownload" class="sheet-audio-player">
							<source :src="utils.parseMediaUrl(sheetData.media_file)">
						</audio>
						<div class="sheet-content-text dgrid-container">
							<p>{{sheetData.description}}</p>
						</div>
					</div>
				</section>
				<div class=""></div>
				<section class="stack-vertical sheet-metadata relative" data-gap="none">
					<!-- MAP CONTAINER -->
					<div v-if="coordinates" :class="`sheet-map-container ${setSheetMapClass} ${mapOpenedClass}`">
						<div class="map-wrapper">
							<div v-if="mobile" class="map-button map-close-button cover selected" @click="toggleMap">
								<base-icon name="arrow-move-right" color-fill="white"/>
							</div>
							<interface-map
								:markers="markers"
								:zoom="14"
								:center="coordinates"
								:interactive="true"
								:interactive-markers="false" />
						</div>
					</div>
					<div class="large-padding stack-vertical metadata-text-cont">
						<div v-for="(g, i) in groupedMetadata.filter((el, i) => i < 2)" class="stack-vertical relative metadata-sheet-text">
							<div class="stack-vertical metadata-sheet-text-block" v-for="(md, y) in g.filter(el => sheetData[el])" :key="y">
								<p class="capitalize-first text--body1-medium">{{$t('sheet_'+md)}}</p>
								<p class="text--body1">{{sheetData[md]}}</p>
							</div>
							<img v-if="i == 0" class="metadata-logo" :src="parsedMapUrl">
							<img v-if="i == 1" class="metadata-logo" :src="parsedIntitutionUrl">
							<div v-if="mobile && i == 0" class="capitalize-first stack-horizontal" @click="toggleMap">{{$t('show_map')}}<base-icon name="arrow-right"/></div>
							<div v-if="g.filter(el => sheetData[el]).length" class="divisor"></div>
						</div>
						<div v-if="metadataExpanded" v-for="(g, i) in groupedMetadata.filter((el, i) => i > 1)" class="stack-vertical relative metadata-sheet-text">
							<div class="stack-horizontal metadata-sheet-text-block" data-justify="spaced" v-for="(md, y) in g.filter(el => sheetData[el])" :key="y">
								<p class="capitalize-first text--body1-medium">{{$t('sheet_'+md)}}</p>
								<p v-if="md == 'url'" class="text--body1"><a :href="sheetData[md]" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">link</a></p>
								<p v-else class="text--body1">{{sheetData[md]}}</p>
							</div>
						</div>
						<base-button-ghost 
							:text="metadataExpanded ? $t('global_bottone_riduci') : $t('global_bottone_espandi')" 
							:icon="metadataExpanded ? 'arrow-move-up' : 'arrow-move-down'"
							@clicked="metadataExpanderHandler" 
						/>
					</div>
				</section>
			</section>
			<section v-else></section>
			<section class="stack-vertical carousel-cont">
				<interface-cards-carousel class="full-width sheets-container" data-align="start"
					:title="$t('related_sheets_title')" 
					:scrollable="true" 
					:filters="relatedSheetsFiltersAvailable"
					see-all-link
					:see-all-href="`explore?${this.relatedType}=${relatedValue}`"
					:columns="4"
					@filter-clicked="filterClicked"
					@see-all-clicked="seeAllClicked">

					<template v-if="relatedSheets.length">
						<!-- <a v-for="item in relatedSheets" :href="`sheet/${item.slug}`" :key="item.id" @click.prevent> -->
							<base-cs-sheet
                 v-for="item in relatedSheets" :key="item.id"
								:item="item"
								ignore-max-width
								@clicked="goToSheet(item.slug)"
							/>								
						<!-- </a> -->
					</template>
					<p v-else> {{ $t('no_related_sheets') }} </p>

				</interface-cards-carousel>
			</section>
			<div class="view-bottom-margin"></div>
		</main>
		<InterfaceShareDesktop 
			:visible="shareDesktopVisible" 
			@exit-modal="() => shareDesktopVisible = false"
			@social-share="socialShare"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import scrollingEvents from '@mixins/scrollingEvents.js';
import InterfaceUserAndTags from '@components/InterfaceUserAndTags.vue';
import InterfaceNavbar from '@components/InterfaceNavbar.vue';
import InterfaceCardsCarousel from '@components/InterfaceCardsCarousel.vue';
import InterfaceShareDesktop from '@components/InterfaceShareDesktop.vue';
import InterfaceMap from '@components/InterfaceMap.vue';
import _ from 'lodash';
import mockup from '@mockup/mockup_data.js';
import utils from '@js/utils.js';

export default {
	name: 'Sheet',
	// metaInfo(){
	// 	const sh = this.sheetData
	// 	return {
	// 		titleTemplate: chunk => (sh == undefined ? `scheda` : sh.title )
	// 	}
	// },
	mixins: [ scrollingEvents ],
	components: { 
		InterfaceUserAndTags, 
		InterfaceNavbar, 
		InterfaceCardsCarousel, 
		InterfaceMap,
		InterfaceShareDesktop
	},
	props: {
		slug: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			userActions: [
				// {icon: 'favorite'},
				{icon: 'download', text: this.$t('download_asset'), tooltipHover: this.$t('download_asset'), tooltipClicked: this.$t('downloaded_asset'), clicked: false},
				{icon: 'share', text: this.$t('share'), tooltipHover: this.$t('share'), tooltipClicked: this.$t('shared'), clicked: false},
				// {icon: 'hyperlink', text: this.$t('sheet_source'), tooltipHover: this.$t('copy_link'), tooltipClicked: this.$t('copied_link'), clicked: false},
			],
			mockup: mockup,
			utils: utils,
			sheetMetadata: [
				"town",
				"province",
				"region",
				"country",
				"institution",
				"source_db",
				"authors",
				"archive",
				"url",
				// "rights",
				"data_type",
				// "category",
				// "format",
				"geodata_latitude",
				"geodata_longitude",
				"people",
				// "reated_at",
				"material",
			],
			sheetData: undefined,
			opened: false,
			sheetTitleBelowBreadcrumb: false,
			syncMode: true,
			relatedSheets: [{}, {}, {}, {}],
			relatedStories: [{}, {}, {}],
			relatedSheetsFilters: [
				{
					type: 'topic',
					label: this.$t('global_filtro_correlati_categoria')
				},
				{
					type: 'database',
					label: this.$t('global_filtro_correlati_database')
				},
				{
					type: 'institution',
					label: this.$t('global_filtro_correlati_ente')
				}
			],
			relatedType: 'topic',
			relatedLoading: {
				sheets: true,
				stories: true
			},
			metadataExpanded: false,
			showTitle: false,
			loadActionUser: '',
			portraitRatioFixClass: '',
			showTooltipShare: false,
			shareDesktopVisible: false
		}
	},
	computed: {
		...mapState({
			mobile: state => state.interface.mobile
		}),
		groupedMetadata() {
			return [
				this.sheetMetadata.slice(0,4),
				this.sheetMetadata.slice(4, 7),
				this.sheetMetadata.slice(7, 13),
				this.sheetMetadata.slice(13, 16),
				this.sheetMetadata.slice(16, 20),
			]
		},
		sheetTags() {
			return this.sheetData.tags ? this.sheetData.tags.split(',') : []
		},
		setSheetMapClass() {
			return this.mobile ? 'fixed-lateral-drawer' : ''
		},
		mapOpenedClass() {
			return this.opened ? 'opened' : ''
		},
		topic() {
			if (this.sheetData) return this.sheetData.category;
		},
		database() {
			if (this.sheetData) return this.sheetData.source_db;
		},
		institution() {
			if (this.sheetData) return this.sheetData.institution;
		},
		relatedSheetsFiltersAvailable() {
			return this.relatedSheetsFilters.filter(el => this[el.type]).map(el => {
				return {...el, selected: el.type == this.relatedType}
			});
		},
		showSheetTitle() {
			return this.mobile ? (this.sheetTitleBelowBreadcrumb ? this.sheetData.title : '') : this.sheetData.title
		},
		parsedMapUrl() {
			return utils.getRegionSvgByName(this.sheetData.region);
		},
		parsedIntitutionUrl() {
			// return require('@assets/images/'+this.sheetData.institution.toUpperCase().toLowerCase()+'_logo.png')
		},
		coordinates() {
			return this.sheetData && this.sheetData.geodata_longitude && this.sheetData.geodata_latitude? [this.sheetData.geodata_longitude, this.sheetData.geodata_latitude] : undefined;
		},
		markers() {
			return this.sheetData ? [{
				geodata_latitude: this.sheetData.geodata_latitude,
				geodata_longitude: this.sheetData.geodata_longitude,
				id: this.sheetData.id
			}] : [];
		},
		userActionsFiltered() {
			let userActions = _.cloneDeep(this.userActions)
			if(this.sheetData) {
				if(this.sheetData.institution == 'Regione FVG' || this.sheetData.source_db == 'Video di micro-narrazione UNISG' || !this.sheetData.media_file) {
					userActions.splice(userActions.findIndex(el => el.icon == 'download'), 1);
				}
			}
			if(this?.sheetData?.url) userActions.push({icon: 'open_url', text: this.$t('sheet_source').toLowerCase(), tooltipHover: this.$t('sheet_source'), tooltipClicked: this.$t('sheet_source'), clicked: false})

			// console.log('user actions', _.cloneDeep(userActions))

			return userActions
		},
		videoPlaceholder() {
			let rtn = require('@assets/images/video_placeholder.png');
			if (this.sheetData['thumb_file']) rtn = utils.parseMediaUrl(this.sheetData['thumb_file']);
			return rtn;
		},
		relatedValue() {
			return this[this.relatedType];
		}
	},
	methods: {
		...mapActions(['getSearchStories']),
		...mapActions('sheets', ['getSheetBySlug', 'getSheets']),
		langCb() {
			this.loadAllData();
		},
		mapMetadatas(from, to) {
			let newData = []
			this.sheetMetadata.slice(from, to).forEach(f => {
				if(this.sheetData[f]) newData.push({field: f, value: this.sheetData[f]})
			})
			console.log('newData', newData);
			return newData;
		},
		toggleMap() {
			this.opened = !this.opened;
		},
		loadAllData() {
			(async () => {
				console.log('slug', this.slug)
				this.sheetData = await this.getSheetBySlug(this.slug);
				// console.log("sheetData", _.cloneDeep(this.sheetData))
				// this.$refs.cover_image.onchange = e => {
				// 	console.log('changed', e)
				// }
				if(this.sheetData.media_file && !this.sheetData.is_video && this.sheetData.data_type.toLowerCase() != 'audio') {
				  let promise = await new Promise(resolve => {
				    const interval = setInterval(() => {
				    	let img = document.querySelector('#sheet-image-cover')
				    	let setImageClass = image => image.height > image.width ? 'portrait' : 'landscape'
				      if (img) {
				        if(img.complete) {
				        	this.portraitRatioFixClass = setImageClass(img)
				        }else {
									img.onload = e => {
										this.portraitRatioFixClass = setImageClass(img)
										console.log(this.portraitRatioFixClass)
									}	
				        }
				        resolve('foo');
				        clearInterval(interval);
				      };
				    }, 1);
				  });
				}
				this.computeAvailableFilters();
				this.getRelatedSheets();
				this.getRelatedStories();
			})()
		},
		computeAvailableFilters() {
			for (let i = 0; i < this.relatedSheetsFilters.length; i++) {
				if (this[this.relatedSheetsFilters[i].type]) {
					this.relatedType = this.relatedSheetsFilters[i].type;
					break;
				}
			}
		},
		async getRelatedSheets() {
			this.$set(this, 'relatedSheets', [{}, {}, {}, {}]);
			let args = { page_size: 5 }
			//topic, database, institution (are computed properties)
			args[this.relatedType] = this[this.relatedType];
			let res = await this.getSheets(args);
			this.relatedSheets = res.json.filter(el => el.slug != this.slug).slice(0,4);
		},
		async getRelatedStories() {
			this.$set(this, 'relatedStories', [{}, {}, {}]);
			
			//TODO MOCKUP!
			setTimeout( () => {
				this.relatedStories = [];
			},2000);
		},
		goToSheet(slug) {
			this.$router.push({name: 'sheet', params: {slug}})
		},
		userAction(type) {
			console.log('userAction', type);
			switch (type) {
				case 'download':
					this.loadActionUser = 'download'
					this.downloadImage()
						.then(res => {
							this.userActions.find(el => el.icon === type).clicked = true
							setTimeout(() => {
								this.userActions.find(el => el.icon === type).clicked = false;
								this.$refs['user-and-tags'].hovered = []
								this.loadActionUser = ''
							}, 1000)
						})
					break;
				case 'share':
					if(!this.mobile) {
						this.shareDesktopVisible = true;
					}else {
						utils.copyLink(null, this.mobile, this.sheetData.title, '');
						this.userActions.find(el => el.icon === type).clicked = true
						setTimeout(() => {
							this.userActions.find(el => el.icon === type).clicked = false;
							this.$refs['user-and-tags'].hovered = []
						}, 1000)
					}
					break;
				case 'hyperlink':
					utils.copyClipboard(location.href, null, 'GeCA | Geoportale della Cultura Alimentare: '+this.sheetData.title)
					this.userActions.find(el => el.icon === type).clicked = true
					setTimeout(() => {
						this.userActions.find(el => el.icon === type).clicked = false;
						this.$refs['user-and-tags'].hovered = []
					}, 1000)
					break;
				case 'open_url':
					let a = document.createElement('a')
					a.target = '_blank';
					a.style.display = 'hidden'
					a.href = this.sheetData.url
					document.body.append(a)
					a.click()
					a.remove()
					break;
			}
		},
		filterClicked(type) {
			// console.log('filterClicked', type);
			this.relatedType = type;
			this.getRelatedSheets();
		},
		seeAllClicked() {
			let query = {}
			//topic, database, institution (are computed properties)
			query[this.relatedType] = this[this.relatedType];
			this.$router.push({ name: 'explore', query })
		},
		metadataExpanderHandler(e) {
			// document.querySelector('.sheet-content').style.height = document.querySelector('.sheet-metadata').style.height + 'px';
			this.metadataExpanded = !this.metadataExpanded
		},
		onScrollBodySheet() {
			if(!this.showTitle) this.showTitle = true;
		},
		async downloadImage() {
			let response = await fetch(this.utils.parseMediaUrl(this.sheetData.media_file))
			let blob = await response.blob()
			let a = document.createElement("a");
			let mimeType = ''
			console.log(this.sheetData.data_type.toLowerCase())
			if(this.sheetData.data_type) {
				switch (this.sheetData.data_type.toLowerCase()) {
					case 'audio':
						mimeType = 'audio/mpeg'
						break;
					case 'video':
						mimeType = 'video/mp4'
						break;
					case 'immagine':
						mimeType = 'image/jpeg'
						break;
				}
			}else {
				if(this.sheetData.is_video) {
					mimeType = 'video/mp4'
				}else {
					mimeType = 'image/jpeg'
				}	
			}
			a.href = URL.createObjectURL(new Blob([blob], {type: mimeType}));
			a.download = "";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},
		socialShare(social) {
			let mainText = {it: `Ecco per te una scheda del Geoportale\n\n`, en: `Here is for you a Geoportal sheet\n\n`}

			switch (social) {
				case 'whatsapp':
					// document.querySelector('#test_whatsapp').click()
					window.open('https://wa.me/?text='+encodeURIComponent(mainText[this.$i18n.locale]) + location.href, '_blank');
					break;
				case 'facebook':
					window.open('https://www.facebook.com/sharer.php?u='+location.href, '_blank');
					break;
				case 'x':
					window.open('https://twitter.com/intent/tweet?text='+encodeURIComponent(mainText[this.$i18n.locale]) + location.href, '_blank');
					break;
				case 'email':
					window.open('mailto:test@gmail.com?subject=&body='+encodeURIComponent(mainText[this.$i18n.locale]) + location.href);
					break;
			}
		}
	},
	mounted() {
		window.onscroll = this.scrollEvent('.sheet-content h2', '.breadcrumb', bool => this.sheetTitleBelowBreadcrumb = bool)
		this.loadAllData();
		utils.launchWhenElementBuild('.sheet-body', () => {
			document.querySelector('.sheet-body').onscroll = () => {
				this.onScrollBodySheet();
			}
		})
	},
	beforeDestroy() {
		window.onscroll = null;
		this.showTooltipShare = false;
	}
}
	
</script>

<style lang="scss" src="@css/views/ViewSheet.scss">
</style>